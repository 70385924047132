<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="角色详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveModel"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="role_model"
          :rules="rules"
          ref="role_model"
          label-width="100px"
        >
          <CopyPaset
            :button_status="this.role_id !== undefined && this.role_id.length > 0"
            @copyInfo="copyInfo(role_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaset"
          ></CopyPaset>
          <el-form-item label="名称:" prop="name">
            <el-input v-model="role_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select
              v-model="role_model.status"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权重:">
            <el-input-number
              v-model="role_model.number"
              :min="0"
              label="权重"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            v-for="(category_item, i) in auth_tree_list"
            :key="category_item.id"
          >
            <el-checkbox
              @change="(val) => handleCheckAllChange(val, category_item)"
              :indeterminate="itemIndeterminate(category_item)"
              v-model="category_item.checkAll"
              >{{ category_item.name }}</el-checkbox
            >
            <el-checkbox-group
              v-model="choose_api_id_list"
              @change="(value) => handleCheckedCitiesChange(value, i)"
            >
              <el-checkbox
                v-for="item in category_item.api_list"
                :label="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CopyPaset from "../../../components/CopyPaste.vue";
export default {
  name: "RoleDetailView",
  components: {
    CopyPaset,
  },
  data() {
    return {
      loading: false,
      role_id: "",
      role_model: {
        id: "",
        name: "",
        number: 0,
        status: 2,
        role_api_list: [],
      },
      choose_api_id_list: [],
      auth_tree_list: [],
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 4, message: "长度大于 4 个字符", trigger: "blur" },
        ],
      },
      checkAll: false,
      isIndeterminate: true,
    };
  },
  created() {
    this.getAuthTree();
    this.role_id = this.$route.query.id;
    if (this.role_id !== undefined && this.role_id.length > 0) {
      this.getRoleModel();
    }
  },
  methods: {
    itemIndeterminate(item) {
      // 查找对应一级的接口存在
      return (
        item.allRule.some((v) => this.choose_api_id_list.indexOf(v) > -1) &&
        !item.allRule.every((v) => this.choose_api_id_list.indexOf(v) > -1)
      );
    },

    handleCheckAllChange(val, item) {
      // 筛选出当前点击的接口列表，进行全选
      const filterArr = this.choose_api_id_list.filter(
        (v) => item.allRule.indexOf(v) === -1
      );
      this.choose_api_id_list = val ? filterArr.concat(item.allRule) : filterArr;
    },
    handleCheckedCitiesChange(value, i) {
      // 根据列表下的接口选择长度进行反选
      let checkedCount = value.length;
      this.auth_tree_list[i].checkAll =
        checkedCount === this.auth_tree_list[i].api_list.length;

      this.auth_tree_list[i].isIndeterminate =
        checkedCount > 0 && checkedCount < this.auth_tree_list[i].api_list.length;
    },
    getAuthTree() {
      let _me = this;
      _me.loading = true;
      let params = {};
      _me.$common
        .httpPost("/api/root/auth/category/find/tree", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.auth_tree_list = res.data;

            _me.auth_tree_list = res.data.map((v) => ({
              ...v,
              checkAll: false,
              itemIndeterminate: true,
              allRule: v.api_list.map((i) => i.id),
            }));
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    getRoleModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.role_id,
      };
      _me.$common
        .httpPost("/api/root/auth/role/find/id", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.role_model = res.data;
            let api_list = _me.role_model.role_api_list;
            if (api_list !== undefined && api_list.length > 0) {
              for (let i = 0; i < api_list.length; i++) {
                let api_model = api_list[i];
                _me.choose_api_id_list.push(api_model.auth_id);
              }
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    saveModel() {
      if (this.role_id !== undefined && this.role_id.length > 0) {
        this.updateModel();
      } else {
        this.newModel();
      }
    },
    updateModel() {
      let _me = this;
      let params = {
        id: _me.role_model.id,
        name: _me.role_model.name,
        number: _me.role_model.number,
        status: _me.role_model.status,
        role_api_list: _me.choose_api_id_list,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/root/auth/role/update", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    newModel() {
      let _me = this;
      let params = {
        name: _me.role_model.name,
        number: _me.role_model.number,
        status: _me.role_model.status,
        role_api_list: _me.choose_api_id_list,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/root/auth/role/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
      localStorage.removeItem("authRoleList");
      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    },
    copyInfo(str) {
      let compare_list = [];
      this.auth_tree_list.forEach((item) => {
        item.api_list.map((ele) => {
          if (this.choose_api_id_list.indexOf(ele.id) != -1) {
            compare_list.push(ele.name);
          }
        });
        str.arr = compare_list;
      });
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaset.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.role_model = model;
      this.role_model.id = this.role_id;

      this.choose_api_id_list = [];
      this.auth_tree_list.forEach((item) => {
        item.api_list.forEach((v) => {
          if (model.arr.indexOf(v.name) != -1) {
            this.choose_api_id_list.push(v.id);
          }
        });
      });
    },
  },
};
</script>

<style scoped></style>
