<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="接口分类详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveModel"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="auth_api_model"
          :rules="rules"
          ref="auth_api_model"
          label-width="100px"
        >
          <el-form-item label="分类:">
            <el-select
              v-model="auth_api_model.category_id"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in category_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称:" prop="name">
            <el-input v-model="auth_api_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select
              v-model="auth_api_model.status"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请求方式:">
            <el-input v-model="auth_api_model.method" clearable></el-input>
          </el-form-item>
          <el-form-item label="链接:">
            <el-input v-model="auth_api_model.identifier" clearable></el-input>
          </el-form-item>
          <el-form-item label="权重:">
            <el-input-number
              v-model="auth_api_model.number"
              :min="0"
              label="权重"
            ></el-input-number>
          </el-form-item>
          <CopyPaset
            :button_status="this.auth_api_id !== undefined && this.auth_api_id.length > 0"
            @copyInfo="copyInfo(auth_api_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaset"
          ></CopyPaset>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CopyPaset from "../../../components/CopyPaste.vue";
export default {
  name: "ApiDetailView",
  components: {
    CopyPaset,
  },
  data() {
    return {
      loading: false,
      category_list: [],
      auth_api_id: "",
      auth_api_model: {
        id: "",
        category_id: "",
        name: "",
        number: 0,
        status: 2,
        method: "",
        identifier: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 4, message: "长度大于 4 个字符", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getCategoryList();
    this.auth_api_id = this.$route.query.id;
    if (this.auth_api_id !== undefined && this.auth_api_id.length > 0) {
      this.getAuthApiModel();
    }
  },
  methods: {
    getCategoryList() {
      let _me = this;
      let key = "authCategoryList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.category_list = val;
        return;
      }

      let params = {
        status: 1,
        page_size: -1,
        page_number: 1,
        sort_fields: "",
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/root/auth/category/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.category_list = res.data.array === undefined ? [] : res.data.array;

            _me.$common.setGlobalStorage(key, _me.category_list);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    getAuthApiModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.auth_api_id,
      };
      _me.$common
        .httpPost("/api/root/auth/find/id", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.auth_api_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    saveModel() {
      if (this.auth_api_id !== undefined && this.auth_api_id.length > 0) {
        this.updateModel();
      } else {
        this.newModel();
      }
    },
    updateModel() {
      let _me = this;
      let params = {
        id: _me.auth_api_model.id,
        category_id: _me.auth_api_model.category_id,
        name: _me.auth_api_model.name,
        number: _me.auth_api_model.number,
        status: _me.auth_api_model.status,
        method: _me.auth_api_model.method,
        identifier: _me.auth_api_model.identifier,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/root/auth/update", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    newModel() {
      let _me = this;
      let params = {
        category_id: _me.auth_api_model.category_id,
        name: _me.auth_api_model.name,
        number: _me.auth_api_model.number,
        status: _me.auth_api_model.status,
        method: _me.auth_api_model.method,
        identifier: _me.auth_api_model.identifier,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/root/auth/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaset.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.auth_api_model = model;
      this.auth_api_model.id = this.auth_api_id;
    },
  },
};
</script>

<style scoped>
.edit-row-dt {
  padding: 5px 0 0px 0;
  margin: 0;
  width: 100%;
}
</style>
