<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="分类:">
              <el-select v-model="category_id" placeholder="请选择" loading-text="加载中...">
                <el-option key="auth-category-all" label="所有" value></el-option>
                <el-option
                  v-for="item in category_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:">
              <el-select v-model="page_status" clearable placeholder="请选择" loading-text="加载中...">
                <el-option
                  v-for="item in $common.CommonStatusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字:">
              <el-input
                v-model="search_key"
                clearable
                size="medium"
                placeholder="请输入关键字"
                @keyup.enter.native="getApiList"
              ></el-input>
            </el-form-item>
            <el-button class="top-button" size="medium" type="primary" round @click="getApiList">过滤</el-button>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="checkModelDetail"
            >添加</el-button>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="auth_api_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="名称">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="API">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.identifier }}</p>
            </template>
          </el-table-column>
          <el-table-column label="分类" width="240">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{
                category_list_map.get(scope.row.category_id)
                ? category_list_map.get(scope.row.category_id)
                : "无"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="最后修改时间" width="180">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ $common.formatTimesToTime(scope.row.created_at) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="权重" width="160">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="$common.CommonStatusList[scope.row.status].tag"
                effect="dark"
              >{{ $common.CommonStatusList[scope.row.status].name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140">
            <template slot-scope="scope">
              <el-button style="color: #03aef9" @click="checkModelDetail(scope.row)" type="text">编辑</el-button>
              <el-button
                style="color: #f03c69"
                @click="removeModelOnclick(scope.row)"
                type="text"
              >移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="prev, pager, next, jumper"
          :page-size="page_size"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "ApiListView",
  data() {
    return {
      loading: false,
      category_id: "",
      category_list: "",
      category_list_map: {},
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      auth_api_list: []
    };
  },
  created() {
    this.category_list_map = new Map();
    this.getCategoryList();
    this.getApiList();
  },
  methods: {
    getCategoryList() {
      let _me = this;
      let key = "authCategoryList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.category_list = val;
        for (let i of _me.category_list) {
          _me.category_list_map.set(i.id, i.name);
        }
        return;
      }

      let params = {
        status: 1,
        page_size: -1,
        page_number: 1,
        sort_fields: ""
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/root/auth/category/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.category_list =
              res.data.array === undefined ? [] : res.data.array;
            for (let i of _me.category_list) {
              _me.category_list_map.set(i.id, i.name);
            }

            _me.$common.setGlobalStorage(key, _me.category_list);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getApiList() {
      let _me = this;
      let params = {
        category_id: _me.category_id,
        key: _me.search_key,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: ""
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/root/auth/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.auth_api_list =
              res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    checkModelDetail(model) {
      let path =
        model && model.id
          ? "admin-auth-api-detail?id=" + model.id
          : "admin-auth-api-detail";
      this.$router.push(path);
    },
    removeModelOnclick(model) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          _me.removeItem(model);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消删除");
        });
    },
    removeItem(model) {
      let _me = this;
      _me.loading = true;
      let params = {
        id: model.id
      };
      _me.$common
        .httpPost("/api/root/auth/delete", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("删除成功");
            _me.getApiList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getApiList();
    }
  }
};
</script>

<style scoped></style>
