<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="接口分类详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveModel"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="category_model"
          :rules="rules"
          ref="category_model"
          label-width="100px"
        >
          <el-form-item label="名称:" prop="name">
            <el-input v-model="category_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select
              v-model="category_model.status"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="路径:">
            <el-input v-model="category_model.identifier" clearable></el-input>
          </el-form-item>
          <el-form-item label="权重:">
            <el-input-number
              v-model="category_model.number"
              :min="0"
              label="权重"
            ></el-input-number>
          </el-form-item>
          <CopyPaset
            :button_status="
              this.auth_category_id !== undefined && this.auth_category_id.length > 0
            "
            @copyInfo="copyInfo(category_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaset"
          ></CopyPaset>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CopyPaset from "../../../components/CopyPaste.vue";

export default {
  name: "AuthCategoryDetail",
  components: {
    CopyPaset,
  },
  data() {
    return {
      loading: false,
      auth_category_id: "",
      category_model: {
        id: "",
        name: "",
        number: 0,
        status: 2,
        identifier: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 4, message: "长度大于 4 个字符", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.auth_category_id = this.$route.query.id;
    if (this.auth_category_id !== undefined && this.auth_category_id.length > 0) {
      this.getCategoryModel();
    }
  },
  methods: {
    getCategoryModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.auth_category_id,
      };
      _me.$common
        .httpPost("/api/root/auth/category/find/id", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.category_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    saveModel() {
      if (this.auth_category_id !== undefined && this.auth_category_id.length > 0) {
        this.updateModel();
      } else {
        this.newModel();
      }
    },
    updateModel() {
      let _me = this;
      let params = {
        id: _me.category_model.id,
        name: _me.category_model.name,
        number: _me.category_model.number,
        status: _me.category_model.status,
        identifier: _me.category_model.identifier,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/root/auth/category/update", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    newModel() {
      let _me = this;
      let params = {
        name: _me.category_model.name,
        number: _me.category_model.number,
        status: _me.category_model.status,
        identifier: _me.category_model.identifier,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/root/auth/category/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
      localStorage.removeItem('authCategoryList')
      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaset.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.category_model = model;
      this.category_model.id = this.auth_category_id;
    },
  },
};
</script>

<style scoped>
.edit-row-dt {
  padding: 5px 0 0px 0;
  margin: 0;
  width: 100%;
}
</style>
