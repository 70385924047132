<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="管理员账号详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveModel"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="auth_user_model"
          :rules="rules"
          ref="auth_user_model"
          label-width="100px"
        >
          <el-form-item label="邮箱:" prop="email">
            <el-input
              style="width: calc(100% - 100px)"
              v-model="auth_user_model.email"
              :disabled="auth_user_id !== undefined"
            ></el-input>
            <el-button
              style="width: 90px; margin-left: 10px"
              :type="auth_user_id !== undefined ? 'info' : 'danger'"
              @click="searchUserOnclick"
              >搜索用户</el-button
            >
          </el-form-item>
          <el-form-item label="用户ID:">
            <el-input v-model="auth_user_model.user_id" disabled></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select
              v-model="auth_user_model.status"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权重:">
            <el-input-number
              v-model="auth_user_model.number"
              :min="0"
              label="权重"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="用户名:">
            <el-input v-model="auth_user_model.username" disabled></el-input>
          </el-form-item>
          <el-form-item label="姓名:">
            <el-input v-model="auth_user_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="auth_user_model.remark"></el-input>
          </el-form-item>
          <el-form-item label="角色:">
            <el-checkbox-group v-model="choose_role_list" @change="$forceUpdate()">
              <el-checkbox v-for="item in auth_role_list" :label="item.id" :key="item.id">
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <CopyPaset
            :button_status="
              this.auth_user_id !== undefined && this.auth_user_id.length > 0
            "
            @copyInfo="copyInfo(auth_user_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaset"
          ></CopyPaset>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CopyPaset from "../../../components/CopyPaste.vue";

export default {
  name: "AdminDetailView",
  components: {
    CopyPaset,
  },
  data() {
    return {
      loading: false,
      auth_user_id: "",
      auth_role_list: [],
      auth_user_model: {
        id: "",
        user_id: "",
        number: 0,
        status: 2,
        username: "",
        name: "",
        remark: "",
        email: "",
        role_list: [],
      },
      choose_role_list: [],
      rules: {
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { min: 4, message: "长度大于 4 个字符", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getAuthRoleList();
    this.auth_user_id = this.$route.query.id;
    if (this.auth_user_id !== undefined && this.auth_user_id.length > 0) {
      this.getAuthUserModel();
    }
  },
  methods: {
    getAuthRoleList() {
      let _me = this;
      let key = "authRoleList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.auth_role_list = val;
        return;
      }

      let params = {
        page_size: -1,
        page_number: 1,
        sort_fields: "created_at",
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/root/auth/role/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.auth_role_list = res.data.array === undefined ? [] : res.data.array;

            _me.$common.setGlobalStorage(key, _me.auth_role_list);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    getAuthUserModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.auth_user_id,
      };
      _me.$common
        .httpPost("/api/root/user/find/id", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.auth_user_model = res.data;
            if (
              _me.auth_user_model.role_list !== undefined &&
              _me.auth_user_model.role_list.length > 0
            ) {
              _me.choose_role_list = [];
              for (let i = 0; i < _me.auth_user_model.role_list.length; i++) {
                let role_model = _me.auth_user_model.role_list[i];
                _me.choose_role_list.push(role_model.role_id);
              }
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    searchUserOnclick() {
      if (this.auth_user_id !== undefined && this.auth_user_id.length > 0) {
        this.$message.error("暂不支持修改用户ID,如需修改请先进行新增");
      } else {
        let _me = this;
        this.$refs["auth_user_model"].validate((valid) => {
          if (valid) {
            _me.loading = true;
            let params = {
              email: _me.auth_user_model.email,
            };
            _me.$common
              .httpPost("/api/user/admin/find/email", params)
              .then((res) => {
                _me.loading = false;
                if (res.code === "1000000") {
                  _me.auth_user_model.user_id = res.data.id;
                  _me.auth_user_model.username = res.data.username;
                  _me.auth_user_model.name = res.data.nick_name;
                  _me.auth_user_model.email = res.data.email;
                } else {
                  _me.$message({
                    message: res.message || "获取数据失败",
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                _me.loading = false;
                _me.$message({
                  message: err || "获取数据失败",
                  type: "error",
                });
              });
          } else {
            return false;
          }
        });
      }
    },
    saveModel() {
      if (this.auth_user_id !== undefined && this.auth_user_id.length > 0) {
        this.updateModel();
      } else {
        this.newModel();
      }
    },
    updateModel() {
      let _me = this;
      let params = {
        id: _me.auth_user_model.id,
        user_id: _me.auth_user_model.user_id,
        number: _me.auth_user_model.number,
        status: _me.auth_user_model.status,
        username: _me.auth_user_model.username,
        name: _me.auth_user_model.name,
        remark: _me.auth_user_model.remark,
        email: _me.auth_user_model.email,
        role_list: _me.choose_role_list,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/root/user/update", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    newModel() {
      let _me = this;
      let params = {
        user_id: _me.auth_user_model.user_id,
        number: _me.auth_user_model.number,
        status: _me.auth_user_model.status,
        username: _me.auth_user_model.username,
        name: _me.auth_user_model.name,
        remark: _me.auth_user_model.remark,
        email: _me.auth_user_model.email,
        role_list: _me.choose_role_list,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/root/user/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaset.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      this.auth_user_model = model;
      this.auth_user_model.id = this.auth_user_id;
    },
  },
};
</script>

<style scoped></style>
